import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Article} from "@interfaces/article";
import {inject} from "@angular/core";
import {ArticlesRepository} from "@app/repositories/articles.repository";

export const ArticlesResolver: ResolveFn<Article[] | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Article[] | undefined> => {
  const articles$ = inject(ArticlesRepository).articles$;
  console.log(`${ArticlesResolver.name}.resolve`);

  return articles$;
};
