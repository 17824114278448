<div class="w-full min-w-full">
  <div class="w-full flex items-center justify-between" (click)="takePicture()">
    <ion-label for="photoButton" class="block">Bild aufnehmen</ion-label>
    <ion-button id="fotoButton" (click)="takePicture()">
      <ion-icon slot="icon-only" name="camera"/>
    </ion-button>
  </div>

  <img id="photo" class="w-full max-h-64 object-contain" #photo alt="" [class.hidden]="!hasPhoto"/>
  @if (model.images && model.images.length > 0) {
    <div>
      <ion-label>Aktuelle Bilder (klicken zum entfernen)</ion-label>
      <div #photosContainer class="flex items-center overflow-y-scroll space-x-3 mb-3">
        @for (photo of model.images; track photo) {
          <img class="w-full max-h-32 max-w-32 object-contain" (click)="removeImage(photo)" src="{{ photo }}"
               alt=""/>
        }
      </div>
    </div>
  }
</div>
