import {Injectable} from "@angular/core";
import {createState, Store} from "@ngneat/elf";
import {selectAllEntities, selectEntity, setEntities, withEntities} from "@ngneat/elf-entities";
import {ArticleCategory} from "@interfaces/article";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {map} from "rxjs";

const {state, config} = createState(withEntities<ArticleCategory>());
const articlesStore = new Store({name: 'article-categories', state, config});

const persist = persistState(articlesStore, {
  key: 'article-categories',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class ArticleCategoriesRepository {
  articleCategories$ = articlesStore.pipe(selectAllEntities());

  constructor() {
    console.log(`${this.constructor.name}.constructor`);

    this.articleCategories$.subscribe(articles => {

    });
  }

  updateArticleCategories(articleCategories: ArticleCategory[]) {
    console.log(`${this.constructor.name}.updateArticleCategories`, articleCategories.length);
    articlesStore.update(setEntities(articleCategories));
  }

  addArticleCategory(articleCategory: ArticleCategory) {
    console.log(`${this.constructor.name}.addArticleCategory`, articleCategory);

    try {
      articlesStore.update(setEntities([articleCategory]));
    } catch (error) {
      console.error(`${this.constructor.name}.addArticleCategory: error`, error);
    }
  }

  filterById(id: string) {
    return articlesStore.pipe(selectEntity(id));
  }

  filterBySlug(slug: string) {
    return this.articleCategories$.pipe(map(articleCategories => articleCategories.find(category => category.slug === slug)));
  }
}
