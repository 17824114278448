import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {from, Observable, of} from "rxjs";
import {Article} from "@interfaces/article";
import {inject} from "@angular/core";
import {ArticlesRepository} from "@app/repositories/articles.repository";

export const ArticleResolver: ResolveFn<Article | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Article | undefined> => {
  const articleSlug = route.paramMap.get('articleSlug');
  console.log(`${ArticleResolver.name}`, articleSlug);

  if (!articleSlug) {
    return of(undefined);
  }

  const article = inject(ArticlesRepository).findBySlug(articleSlug);
  return from(article);
};
