import {inject, Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular/standalone";
import {OnboardingSheetComponent} from "@app/components/user/onboarding-sheet/onboarding-sheet.component";
import {OnboardingSheetsService} from "@services/api";
import {firstValueFrom} from "rxjs";
import {OnboardingSheet} from "@app/interfaces";
import {SettingsRepository} from "@app/repositories/settings.repository";

@Injectable({
  providedIn: 'root'
})
export class OnboardingSheetService {
  private modal: HTMLIonModalElement | undefined;
  private settingsRepository: SettingsRepository = inject(SettingsRepository);
  private onboardingSheetService: OnboardingSheetsService = inject(OnboardingSheetsService);

  constructor(private modalController: ModalController) {
  }

  async maybeShowOnboarding() {
    this.settingsRepository.settings$.subscribe(async (settings) => {
      if (settings.hasSeenOnboarding) {
        return;
      }

      const sheets = await firstValueFrom(this.onboardingSheetService.fetch());
      if (sheets && sheets.length > 0) {
        await this.open(sheets);
      }
    });
  }

  async open(sheets: OnboardingSheet[]) {
    console.log(`${this.constructor.name}.open`);
    if (this.modal) {
      return;
    }

    this.modal = await this.modalController.create({
      component: OnboardingSheetComponent,
      componentProps: {sheets},
      backdropDismiss: false,
      cssClass: 'auto-height',
    });

    this.modal.addEventListener('close-onboarding-sheet', () => this.close());

    await this.modal.present();
  }

  async close() {
    this.settingsRepository.setHasSeenOnboarding();
    console.log(`${this.constructor.name}.close`, this.modal);
    await this.modal?.dismiss();
  }
}
