import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {Poll} from "@interfaces/poll";
import {from, Observable, of} from "rxjs";
import {inject} from "@angular/core";
import {PollsRepository} from "@app/repositories/polls.repository";

export const PollResolver: ResolveFn<Poll | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Poll | undefined> => {
  const pollSlug = route.paramMap.get('pollSlug');
  console.log(`${PollResolver.name}`, pollSlug);

  if (!pollSlug) {
    return of(undefined);
  }

  const poll = inject(PollsRepository).findBySlug(pollSlug);
  return from(poll);
}
