import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {from, Observable, of} from "rxjs";
import {Article} from "@interfaces/article";
import {inject} from "@angular/core";
import {ArticlesRepository} from "@app/repositories/articles.repository";

export const ArticlesByCategoryResolver: ResolveFn<Article[] | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Article[] | undefined> => {
  const categorySlug = route.paramMap.get('categorySlug');
  console.log(`${ArticlesByCategoryResolver.name}.resolve:`, categorySlug);

  if (!categorySlug) {
    return of([]);
  }

  const articles$ = inject(ArticlesRepository).filterByCategorySlug(categorySlug);
  console.log(`${ArticlesByCategoryResolver.name}.resolve`);

  return from(articles$);
};
