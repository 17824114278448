import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {from, Observable, of} from "rxjs";
import {ArticleCategory} from "@interfaces/article";
import {inject} from "@angular/core";
import {ArticleCategoriesRepository} from "@app/repositories/article-categories.repository";

export const ArticleCategoryResolver: ResolveFn<ArticleCategory | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<ArticleCategory | undefined> => {
  const categorySlug = route.paramMap.get('categorySlug');
  console.log(`${ArticleCategoryResolver.name}.resolve`, categorySlug);

  if (!categorySlug) {
    return of(undefined);
  }

  const category = inject(ArticleCategoriesRepository).filterBySlug(categorySlug);

  return from(category);
};
