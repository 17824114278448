import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Article} from "@interfaces/article";
import {inject} from "@angular/core";
import {ArticlesRepository} from "@app/repositories/articles.repository";
import {Poll} from "@interfaces/poll";
import {PollsRepository} from "@app/repositories/polls.repository";

export const PollsResolver: ResolveFn<Poll[] | undefined> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<Poll[] | undefined> => {
  const polls$ = inject(PollsRepository).polls$;
  console.log(`${PollsResolver.name}.resolve`);

  return polls$;
};
