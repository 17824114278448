import {Injectable} from "@angular/core";
import {createState, Store} from "@ngneat/elf";
import {
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities
} from "@ngneat/elf-entities";
import {Article, ArticleCategory} from "@interfaces/article";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {firstValueFrom} from "rxjs";

const {state, config} = createState(withEntities<Article>());
const articlesStore = new Store({name: 'articles', state, config});

const persist = persistState(articlesStore, {
  key: 'articles',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class ArticlesRepository {
  articles$ = articlesStore.pipe(selectAllEntities());

  constructor() {
    console.log(`${this.constructor.name}.constructor`);

    this.articles$.subscribe(articles => {

    });
  }

  update(articles: Article[]) {
    console.log(`${this.constructor.name}.updateArticles`, articles.length);
    articlesStore.update(setEntities(articles));
  }

  add(article: Article) {
    console.log(`${this.constructor.name}.addArticle`, article);

    try {
      articlesStore.update(upsertEntities(article));
    } catch (error) {
      console.error(`${this.constructor.name}.addArticle: error`, error);
    }
  }

  async getCategories(): Promise<ArticleCategory[]> {
    const articles = await firstValueFrom(articlesStore.pipe(selectAllEntities()));
    const categories = articles.map(article => article.category);
    // return unique categories filtered by id
    return categories
      .filter((category, index, self) => self.findIndex(t => t.id === category.id) === index)
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  async filterByCategory(category: ArticleCategory): Promise<Article[]> {
    const articles = await firstValueFrom(articlesStore.pipe(selectAllEntities()));
    return articles.filter(article => article.category.id === category.id);
  }

  async filterByCategorySlug(categorySlug: string): Promise<Article[]> {
    const articles = await firstValueFrom(articlesStore.pipe(selectAllEntities()));
    return articles.filter(article => article.category.slug === categorySlug);
  }

  async findBySlug(slug: string): Promise<Article | undefined> {
    const articles = await firstValueFrom(articlesStore.pipe(selectAllEntities()));
    return articles.find(article => article.slug === slug);
  }

  filterById(id: string) {
    return articlesStore.pipe(selectEntity(id));
  }

  remove(article: Article) {
    console.log(`${this.constructor.name}.remove`, article);
    articlesStore.update(deleteEntities(article.id));
  }
}
