import {inject, Injectable} from "@angular/core";
import {ArticleCategory} from "@interfaces/article";
import {firstValueFrom, Observable} from "rxjs";
import {ApiService} from "@app/services";
import {SettingsRepository} from "@app/repositories/settings.repository";
import {ArticleCategoriesRepository} from "@app/repositories/article-categories.repository";

@Injectable({providedIn: 'root'})
export class ArticleCategoriesService extends ApiService {

  private articleCategoriesRepository: ArticleCategoriesRepository = inject(ArticleCategoriesRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  fetch(): Observable<ArticleCategory[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/article-categories`);
  }

  private cacheCollection(categories: ArticleCategory[]) {
    console.log(`${this.constructor.name}.cacheArticles`);
    this.settingsRepository.setArticleCategoriesUpdatedAt();
    this.articleCategoriesRepository.updateArticleCategories(categories);
  }

  private async cacheArticleItem(category: ArticleCategory) {
    console.log(`${this.constructor.name}.cacheArticleItem`, category);
    this.articleCategoriesRepository.addArticleCategory(category);
  }

  async getOneFromApi(id: string): Promise<ArticleCategory> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const category = await firstValueFrom(this.get(`/articles/${id}`)).catch(() => null);

    if (category) {
      this.articleCategoriesRepository.addArticleCategory(category);
      this.cacheArticleItem(category);
    }

    return category;
  }

  async getAllFromApi(): Promise<ArticleCategory[]> {
    return firstValueFrom(this.fetch()).then((categories: ArticleCategory[]) => {
      this.cacheCollection(categories);
      return categories;
    }).catch(() => []);
  }

  async clearCache() {
    console.log(`${this.constructor.name}.clearCache`);
    this.settingsRepository.removeArticleCategoriesUpdatedAt();
    this.articleCategoriesRepository.updateArticleCategories([]);
  }
}
