import {Injectable} from "@angular/core";
import {createState, Store} from "@ngneat/elf";
import {
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities
} from "@ngneat/elf-entities";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {firstValueFrom} from "rxjs";
import {Poll} from "@interfaces/poll";

const {state, config} = createState(withEntities<Poll>());
const pollsStore = new Store({name: 'polls', state, config});

const persist = persistState(pollsStore, {
  key: 'polls',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class PollsRepository {
  polls$ = pollsStore.pipe(selectAllEntities());

  constructor() {
    console.log(`${this.constructor.name}.constructor`);

    this.polls$.subscribe(articles => {

    });
  }

  update(polls: Poll[]) {
    console.log(`${this.constructor.name}.updatePolls`, polls.length);
    pollsStore.update(setEntities(polls));
  }

  add(poll: Poll) {
    console.log(`${this.constructor.name}.addPoll`, poll);

    try {
      pollsStore.update(upsertEntities(poll));
    } catch (error) {
      console.error(`${this.constructor.name}.addPoll: error`, error);
    }
  }

  async findBySlug(slug: string): Promise<Poll | undefined> {
    const polls = await firstValueFrom(pollsStore.pipe(selectAllEntities()));
    return polls.find(entry => entry.slug === slug);
  }

  filterById(id: string) {
    return pollsStore.pipe(selectEntity(id));
  }

  remove(poll: Poll) {
    console.log(`${this.constructor.name}.removePoll`, poll);
    pollsStore.update(deleteEntities(poll.id));
  }
}
