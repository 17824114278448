import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  Signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {News, Owner} from "@app/interfaces";
import {
  AlertController,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  LoadingController,
  NavController
} from "@ionic/angular/standalone";
import {camera, close, videocam} from "ionicons/icons";
import {addIcons} from "ionicons";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {formatISO, roundToNearestMinutes} from "date-fns";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {AuthService, CameraService} from "@app/services";
import {NewsService} from "@services/api";
import {HttpEventType} from "@angular/common/http";
import {NgIf} from "@angular/common";
import {firstValueFrom} from "rxjs";
import {environment} from "@env/environment";
import {NewsRepository} from "@app/repositories/news.repository";
import {TakePictureComponent} from "@app/components/form/take-picture/take-picture.component";

@Component({
  selector: 'app-update-news-sheet',
  templateUrl: './update-news-sheet.component.html',
  styleUrls: ['./update-news-sheet.component.scss'],
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTitle,
    IonFooter,
    FormsModule,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonDatetime,
    IonDatetimeButton,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    NgIf,
    ReactiveFormsModule,
    TakePictureComponent
  ]
})
export class UpdateNewsSheetComponent implements OnInit {

  @Input() model: News;
  @ViewChild('photo', {static: false}) photo: HTMLImageElement | undefined;

  public places: Signal<any> = computed(() => this.authService.currentUser()?.places);
  public isLoading: WritableSignal<boolean> = signal(false);

  public form: FormGroup;
  public hasPhoto: boolean = false;

  protected uploadProgress: number;
  protected loading: HTMLIonLoadingElement | undefined;

  private selectedVideo: PickedFile | null = null;

  private el: ElementRef = inject(ElementRef);
  private fb: FormBuilder = inject(FormBuilder);
  private authService: AuthService = inject(AuthService);
  private newsService: NewsService = inject(NewsService);
  private newsRepository: NewsRepository = inject(NewsRepository);
  private cameraService: CameraService = inject(CameraService);
  private alertController: AlertController = inject(AlertController);
  private loadingController: LoadingController = inject(LoadingController);
  private navController: NavController = inject(NavController);

  constructor() {
    console.log(`${this.constructor.name}.constructor`, this.model);
    addIcons({close, camera, videocam});

    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(5000)]],
      created_at: [formatISO(roundToNearestMinutes(new Date(), {
        nearestTo: 15,
        roundingMethod: 'ceil'
      })), [Validators.required]],
      place_id: [null, [Validators.required]],
      photo: ['', []],
      videoPath: ['', [],],
    });

    effect(() => {
      console.log(`${this.constructor.name}.ngOnInit: places`, this.places());

      if (this.places()?.length === 1) {
        this.form.patchValue({place_id: this.places()[0].id});
      }
    });
  }

  ngOnInit() {
    console.log(`${this.constructor.name}.ngOnInit`, this.model);

    if (this.model) {
      this.form.patchValue(this.model);
    }
  }

  save() {
    this.isLoading.set(true);

    let newsForm: News = this.form.value;

    newsForm.owner = {id: this.form.get('place_id')?.value} as Owner;
    newsForm.created_at = new Date(this.form.value.created_at).toISOString();
    newsForm.new_images = [this.form.value.photo].filter(Boolean);

    console.log(`${this.constructor.name}.save: form`, this.form.valid, this.form.errors, this.form.value);

    if (this.form.valid) {
      if (!this.model.id) {
        this.create(newsForm);
      } else {
        this.update(newsForm);
      }
    } else {
      this.isLoading.set(false);
      this.form.markAllAsTouched();
    }
  }

  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-update-news-sheet', {bubbles: true}));
  }

  protected async create(newsForm: News) {
    const news = await firstValueFrom(this.newsService.create(newsForm)).catch((error) => {
      this.onError(error);
      return;
    });

    if (news) {
      await this.onSuccess(news);
    }
  }

  protected async update(newsForm: News) {
    newsForm.id = this.model.id;
    newsForm.images = this.model.images;

    const news = await firstValueFrom(this.newsService.update(newsForm)).catch((error) => {
      this.onError(error, true);
      return;
    });

    console.log(`${this.constructor.name}.onSubmit: response`, news);
    if (news) {
      await this.onSuccess(news, true);
    }
  }

  protected async onSuccess(news: News, isUpdate: boolean = false) {
    await this.newsRepository.addNews(news);

    const upload = await this.uploadVideo(news);
    console.log(`${this.constructor.name}.onSubmit: upload`, upload);
    if (upload) {
      await this.newsRepository.addNews(upload);
    }


    const message = isUpdate ? 'News wurde erfolgreich aktualisiert.' : 'News wurde erfolgreich erstellt.';
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK'],
    });
    await alert.present();

    this.isLoading.set(false);
    this.close();
    await this.navController.navigateForward(`/news/${news.owner.slug}/${news.slug}`);
  }

  protected async onError(error: any, isUpdate: boolean = false) {
    console.error(`${this.constructor.name}.save: error`, error);

    const message = isUpdate ? 'News konnte nicht aktualisiert werden, bitte versuche es erneut.' : 'News konnte nicht erstellt werden, bitte versuche es erneut.';
    const alert = await this.alertController.create({
      message: message,
      buttons: ['OK'],
    });
    await alert.present();

    this.isLoading.set(false);
  }

  async uploadVideo(news: News): Promise<News | null> {
    return new Promise<News | null>(async (resolve, reject) => {
      if (!this.selectedVideo) {
        console.log(`${this.constructor.name}.uploadVideo: no video selected`);
        return resolve(null);
      }

      this.loading = await this.loadingController.create({
        message: 'Video wird hochgeladen...',
      });
      await this.loading.present();

      console.log(`${this.constructor.name}.uploadVideo: `, this.selectedVideo);

      this.newsService.uploadVideo(news, this.selectedVideo).subscribe({
        next: async (response) => {
          if (response.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (response.loaded / response.total));
            console.log(`${this.constructor.name}.onSubmit: upload progress`, this.uploadProgress);
            if (this.loading) {
              this.loading.message = `Wird hochgeladen... ${this.uploadProgress}%`;
            }

            return;
          }

          if (response.type == HttpEventType.Response) {
            console.log(`${this.constructor.name}.onSubmit: upload response`, response);

            if (this.loading) {
              await this.loading.dismiss();
            }
            return resolve(response.body.data as News);
          }
        },
        error: async (error) => {
          console.error(`${this.constructor.name}.onSubmit: upload error`, error);
          if (this.loading) {
            await this.loading.dismiss();
          }

          this.alertController.create({
            message: 'Video konnte nicht hochgeladen werden, bitte versuche es erneut.',
            buttons: ['OK'],
          }).then((alert) => alert.present());

          return resolve(null);
        }
      });
    });
  }

  async chooseVideo() {
    const result = await this.cameraService.selectVideo();
    if (result && result.files.length && result.files[0]) {
      const video = result.files[0];
      if (video.size > environment.maxUploadFileSize) {
        const alert = await this.alertController.create({
          message: `Die Datei ist zu groß. Bitte wähle eine Datei, die kleiner als ${environment.maxUploadFileSize / 1024 / 1024} MB ist.`,
          buttons: ['OK'],
        });
        await alert.present();
        return;
      }

      const value = result.files[0].name + ' (' + (result.files[0].size / 1024 / 1024).toFixed(0) + ' MB)';
      this.form.patchValue({videoPath: value});
      this.selectedVideo = video;
    }
  }
}
