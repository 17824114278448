import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {IUser, LoginResponse, TimelineEntry, User} from "../interfaces";
import {ApiService} from "./api.service";
import {StorageService} from "./storage.service";
import {SettingsRepository} from "@app/repositories/settings.repository";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {
  public currentAccessToken: WritableSignal<null | string> = signal(null);
  public currentUser: WritableSignal<User | null> = signal(null);
  public isAuthenticated: WritableSignal<boolean> = signal(false);
  public canCreateNews: WritableSignal<boolean> = signal(false);
  public canCreateEvents: WritableSignal<boolean> = signal(false);
  public canCreatePlaces: WritableSignal<boolean> = signal(false);
  public fcmToken: WritableSignal<string> = signal('');

  private storage = inject(StorageService);
  private settings = inject(SettingsRepository);

  constructor() {
    super();
    console.log(`${this.constructor.name}.constructor`);
  }

  canUpdate(model: TimelineEntry) {
    if (!model) {
      console.warn('No timeline entry');
    }
    const user = this.currentUser();
    if (!user) {
      return false;
    }

    return user.places.map(place => place.id).includes(model.owner.id);
  }

  login(email: string, password: string, deviceName: string | null = null): Observable<LoginResponse> {
    return this.post(`/user/authenticate`, {
      email: email,
      password: password,
      device_name: deviceName,
    });
  }

  logout() {
    return new Promise<void>(async (resolve) => {
      await this.storage.remove('user');
      await this.storage.remove('accessToken');
      this.settings.removeUser();
      this.settings.removeAccessToken();
      this.currentUser.set(null);
      this.currentAccessToken.set(null);
      this.isAuthenticated.set(false);
      this.canCreateNews.set(false);
      this.canCreateEvents.set(false);
      this.canCreatePlaces.set(false);

      return resolve();
    });
  }

  fetch(): Observable<IUser> {
    return this.get(`/user?include=settings`);
  }

  updateUser(user: User) {
    this.currentUser.set(user);
    if (user.fcm_token) {
      this.fcmToken.set(user.fcm_token);
    }

    this.settings.setUser(user);
    console.log(this.constructor.name + '.updateUser: sync user', user.email);
  }

  checkAccessToken() {
    return new Promise<User | null>(async (resolve, reject) => {
      const settings = await firstValueFrom(this.settings.settings$);

      let accessToken = settings.accessToken;
      if (!accessToken) {
        accessToken = await this.storage.get('accessToken');
      }

      if (accessToken) {
        console.log(this.constructor.name + '.checkAccessToken: found local token');
        this.settings.setAccessToken(accessToken);
        this.currentAccessToken.set(accessToken);

        this.fetch().subscribe({
          next: (data: IUser) => {
            if (data) {
              const user = new User(data);
              this.updateUser(user);

              this.isAuthenticated.set(true);
              this.canCreatePlaces.set(true);

              if (user.places.length > 0) {
                this.canCreateNews.set(true);
                this.canCreateEvents.set(true);
              }

              resolve(user);
            }
          },
          error: (error) => {
            console.warn(this.constructor.name + '.checkAccessToken:', 'error while fetching user:', error);
            this.storage.remove('accessToken');
            this.currentAccessToken.set(null);
            this.logout();
            reject(error);
          }
        });
      } else {
        console.log(this.constructor.name + '.checkAccessToken: no local token found', accessToken);
        resolve(null);
      }
    });
  }

  setAccessToken(accessToken: string): Promise<User> {
    if (accessToken.indexOf('Bearer') > -1 || accessToken.indexOf('|') > -1) {
      accessToken = <string>accessToken.split('|').pop();
    }

    return new Promise((resolve, reject) => {
      this.currentAccessToken.set(accessToken);
      console.log(`${this.constructor.name}.setAccessToken: set new access token:`, accessToken);

      //this.storage.set('accessToken', accessToken);
      this.settings.setAccessToken(accessToken);

      setTimeout(() => {
        this.checkAccessToken().then((user: User | null) => {
          if (user) {
            resolve(user);
          } else {
            reject();
          }
        });
      }, 250);
    });
  }
}
