import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {Place, User} from "@app/interfaces";
import {ApiService} from "@app/services";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {environment} from "@env/environment";
import {PlacesRepository} from "@app/repositories/places.repository";
import {SettingsRepository} from "@app/repositories/settings.repository";

@Injectable({
  providedIn: 'root'
})
export class PlacesService extends ApiService {
  private placesRepository: PlacesRepository = inject(PlacesRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  fetch(): Observable<Place[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/places?include=categories,locations,people`);
  }

  async requestAccess(place: Place, user: User): Promise<{ success: Boolean }> {
    console.log(`${this.constructor.name}.requestAccess`, place, user);
    return firstValueFrom(this.post(`/places/request-access`, {place_id: place.id}));
  }

  private cachePlacesCollection(places: Place[]) {
    console.log(`${this.constructor.name}.cachePlaces`);
    this.settingsRepository.setPlacesUpdatedAt();
    this.placesRepository.updateEntries(places);
  }

  public async getAllFromApi(): Promise<Place[]> {
    return firstValueFrom(this.fetch()).then((fetchedEntries: Place[]) => {
      this.cachePlacesCollection(fetchedEntries);
      console.log(`${this.constructor.name}.getAllFromApi`, fetchedEntries?.length);

      return fetchedEntries;
    });
  }

  async clearCache() {
    this.settingsRepository.removePlacesUpdatedAt();
    this.placesRepository.updateEntries([]);
  }

  uploadVideo(place: Place, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, place.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/places/${place.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }
}
