import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, map, Observable, throwError} from "rxjs";
import {inject, Injectable, signal, WritableSignal} from "@angular/core";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public isLoading: WritableSignal<boolean> = signal(false);

  protected http = inject(HttpClient);

  constructor() {
  }

  public get<T>(url: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + url).pipe(map(this.extractData), catchError(this.handleError));
  }

  public post<T>(url: string, data: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + url, data).pipe(map(this.extractData), catchError(this.handleError));
  }

  public put<T>(url: string, data: any): Observable<any> {
    return this.http.put(environment.apiBaseUrl + url, data).pipe(map(this.extractData), catchError(this.handleError));
  }

  public delete<T>(url: string): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + url).pipe(map(this.extractData), catchError(this.handleError));
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (error.hasOwnProperty('error') && error.error) {
        const body = (error.error.hasOwnProperty('message')) ? error.error.message : error.error;
        console.error(`RestApi.handleError: ${error.status}: ` + `${body}`);
      } else {
        console.error('RestApi.handleError: caught weird error:', error);
      }
    }

    return throwError(() => error);
  }

  protected extractData<T>(res: { data?: T }): T | null {
    if (!res) {
      return null;
    }
    return res.hasOwnProperty('data') ? res.data as T : res as T;
  }
}
